import { InfoBadge } from './InfoBadge'
import Badge from '~/core/ui/Badge'

export function StatusBadge({ status }: { status?: string }) {
  if (status === 'COMPLETED') {
    return (
      <Badge color="success" className="w-fit pointer-events-none ">
        Completed
      </Badge>
    )
  }
  if (status === 'JOINING_CALL') {
    return (
      <InfoBadge text="Joining call" />
    )
  }
  if (status === 'IN_CALL_RECORDING') {
    return (
      <InfoBadge text="Recording" />
    )
  }
  if (status === 'IN_WAITING_ROOM') {
    return (
      <InfoBadge text="In waiting room" />
    )
  }
  if (status === 'PROCESSING') {
    return (
      <InfoBadge text="Processing" />
    )
  }
  if (status === 'ERROR') {
    return (
      <Badge color="error" className="w-fit">
        Failed
      </Badge>
    )
  }
  return null
}
